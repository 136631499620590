import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >
<path d="M3693 5829 c-10 -10 -13 -109 -13 -426 l0 -413 23 -4 c48 -11 418
-15 518 -7 154 13 230 61 288 181 25 53 26 60 26 245 0 189 0 190 -29 253 -32
69 -71 108 -149 150 l-52 27 -300 3 c-230 2 -303 0 -312 -9z m247 -309 l0
-240 62 0 c54 0 66 4 90 27 15 15 28 30 28 35 0 4 -25 8 -55 8 l-56 0 3 83 3
82 208 3 207 2 0 -205 0 -205 -91 0 -90 0 2 50 c2 27 1 52 -2 55 -3 2 -30 -20
-59 -50 l-54 -55 -183 0 -183 0 0 325 0 325 85 0 85 0 0 -240z"/>
<path d="M6260 5390 l0 -230 30 0 30 0 0 230 0 230 -30 0 -30 0 0 -230z"/>
<path d="M7307 5613 c-4 -3 -7 -37 -7 -75 0 -37 -2 -68 -4 -68 -2 0 -25 9 -51
21 -92 41 -189 -1 -219 -95 -21 -64 -20 -99 4 -145 46 -91 140 -121 234 -73
27 13 31 13 41 -2 7 -9 22 -16 34 -16 21 0 21 2 21 230 l0 230 -23 0 c-13 0
-27 -3 -30 -7z m-60 -173 c45 -27 56 -53 51 -122 -6 -83 -36 -112 -113 -112
-47 0 -59 4 -82 27 -22 22 -28 37 -31 89 -3 54 -1 67 18 91 40 50 102 61 157
27z"/>
<path d="M4742 5368 l3 -213 54 -3 c87 -5 180 14 225 45 54 38 77 82 83 156 4
54 1 69 -24 117 -23 43 -40 61 -78 82 -46 25 -59 28 -158 28 l-107 0 2 -212z
m244 99 c30 -32 34 -43 34 -91 0 -30 -4 -66 -10 -80 -15 -41 -66 -66 -132 -66
l-58 0 0 141 0 141 66 -4 c62 -4 69 -7 100 -41z"/>
<path d="M5610 5535 c0 -39 -3 -45 -21 -45 -17 0 -20 -5 -17 -32 2 -21 8 -33
18 -33 12 0 16 -21 20 -105 3 -58 9 -112 13 -121 12 -24 45 -38 100 -41 l47
-3 0 37 c0 37 -1 38 -35 38 l-35 0 0 100 c0 75 3 100 13 99 44 -6 57 1 57 31
0 28 -3 30 -35 30 -35 0 -35 0 -35 45 l0 45 -45 0 -45 0 0 -45z"/>
<path d="M6493 5495 c-77 -33 -110 -97 -101 -194 12 -122 143 -184 255 -121
95 53 112 192 35 273 -51 54 -125 70 -189 42z m140 -61 c35 -26 52 -89 38
-140 -20 -74 -83 -108 -156 -84 -91 30 -103 187 -17 235 34 19 101 14 135 -11z"/>
<path d="M6903 5495 c-19 -8 -35 -10 -38 -4 -4 5 -19 9 -36 9 l-29 0 0 -170 0
-170 30 0 30 0 0 109 c0 150 9 168 91 185 19 4 25 11 24 28 -2 28 -28 33 -72
13z"/>
<path d="M5250 5484 c-66 -28 -90 -72 -90 -161 0 -103 51 -163 145 -171 44 -4
55 -1 82 22 l32 27 3 -23 c3 -20 9 -23 43 -23 l40 0 3 168 2 167 -45 0 c-33 0
-45 -4 -45 -15 0 -19 2 -19 -45 5 -46 24 -77 25 -125 4z m125 -70 c23 -9 45
-57 45 -98 0 -53 -36 -89 -88 -89 -41 0 -60 12 -80 50 -23 42 -10 102 27 130
17 13 69 17 96 7z"/>
<path d="M5905 5486 c-85 -37 -119 -154 -72 -246 42 -84 121 -111 202 -70 22
11 41 20 42 20 2 0 3 -9 3 -20 0 -17 7 -20 40 -20 l40 0 0 170 0 170 -40 0
c-29 0 -40 -4 -40 -15 0 -19 2 -19 -45 5 -45 23 -87 25 -130 6z m145 -92 c19
-19 25 -35 25 -69 0 -59 -37 -99 -91 -99 -98 0 -117 157 -24 193 32 12 59 5
90 -25z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
